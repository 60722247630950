import querystring from 'querystring';
import { user } from '@/tools';
import api from '@/common/api';

const tools = {
  setUtmSessionFromQuerystring() {
    if (!window.location.search) return;

    const qs: any = querystring.parse(window.location.search.substring(1));

    if (qs.utm_source) sessionStorage.setItem('utm_source', qs.utm_source);
    if (qs.utm_medium) sessionStorage.setItem('utm_medium', qs.utm_medium);
    if (qs.utm_campaign) sessionStorage.setItem('utm_campaign', qs.utm_campaign);
    if (qs.utm_term) sessionStorage.setItem('utm_term', qs.utm_term);
    if (qs.referrer_tag) sessionStorage.setItem('referrer_tag', qs.referrer_tag);
    if (qs.postback) sessionStorage.setItem('postback', qs.postback);
    if (qs.agency) sessionStorage.setItem('agency', qs.agency);
  },
  getUtmQuerystringFromSession() {
    const utmSource = sessionStorage.getItem('utm_source');
    const utmMedium = sessionStorage.getItem('utm_medium');
    const utmCampaign = sessionStorage.getItem('utm_campaign');
    const utmTerm = sessionStorage.getItem('utm_term');
    const referrerTag = sessionStorage.getItem('referrer_tag');
    const postBack = sessionStorage.getItem('postback');
    const agency = sessionStorage.getItem('agency');

    let utm = '';
    if (utmSource) utm += `&utm_source=${utmSource}`;
    if (utmMedium) utm += `&utm_medium=${utmMedium}`;
    if (utmCampaign) utm += `&utm_campaign=${utmCampaign}`;
    if (utmTerm) utm += `&utm_term=${utmTerm}`;
    if (referrerTag) utm += `&referrer_tag=${referrerTag}`;
    if (postBack) utm += `&postback=${encodeURIComponent(postBack)}`;
    if (agency) utm += `&agency=${agency}`;
    if (utm) utm = utm.substring(1);

    return utm;
  },
  openAppStore() {
    window.open(`/store?${this.getUtmQuerystringFromSession()}`);
  },
  async logout(logoutForm) {
    localStorage.removeItem('key');
    localStorage.removeItem('name');
    localStorage.removeItem('ellaEmail');
    localStorage.removeItem('isOutLink');
    localStorage.removeItem('isWebLogin');
    localStorage.removeItem('mode');
    localStorage.removeItem('cusId');

    localStorage.removeItem('session.loggedInUser');

    localStorage.removeItem('session.myGoodsInfo');
    localStorage.removeItem('session.mySegInfo');
    localStorage.removeItem('session.myLmsInfo');
    localStorage.removeItem('session.myBasicInfo');

    const form = logoutForm;
    form.action = '/home/logout';
    form.method = 'post';

    const csrf = await api.cmsGet('/home/token');
    // eslint-disable-next-line no-underscore-dangle
    form._token.value = csrf;
    form.submit();
  },
  isLoggedIn() {
    return !!user.getLoggedInUser();
  },

  isPromotionClosed(status: number, toDate: string): boolean {
    const loggedInUser = user.getLoggedInUser();

    const isDone = status === 2;
    const isReady = status === 0;
    const isTestUser = loggedInUser && loggedInUser.isTestUser;
    const isTimeOver = new Date(toDate.split('-').join('/')).getTime() < new Date().getTime();

    if (isDone || isTimeOver || (isReady && !isTestUser)) {
      return true;
    }

    return false;
  },
};

export default tools;
