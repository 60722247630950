import { render } from "./Channel.vue?vue&type=template&id=09712d58"
import script from "./Channel.vue?vue&type=script&lang=js"
export * from "./Channel.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "09712d58"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('09712d58', script)) {
    api.reload('09712d58', script)
  }
  
  module.hot.accept("./Channel.vue?vue&type=template&id=09712d58", () => {
    api.rerender('09712d58', render)
  })

}

script.__file = "src/common/Channel.vue"

export default script