<template>
  <div class="promotion-banners" v-if="!isBannerClosed && banners?.length > 0">
    <div class="close" @click="closeBanner" />
    <Swiper
      class="swiper"
      direction="vertical"
      :loop="true"
      :slidesPerView="1"
      :speed="500"
      :autoplay="{ dalay: 3000, disableOnInteraction: false }"
    >
      <SwiperSlide v-for="(banner, index) in banners" :key="index" class="slide">
        <a
          class="banner"
          :href="banner.action.url"
          :style="{
            background: `url(${banner.backgroundImage})`,
          }"
        >
          <img :src="banner.image" />
        </a>
      </SwiperSlide>
    </Swiper>
  </div>
</template>

<script lang="ts">
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';

SwiperCore.use([Autoplay]);

export default {
  name: 'Banner',
  props: {
    banners: {
      type: Array,
      requierd: true,
    },
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      isBannerClosed: false,
    };
  },
  methods: {
    closeBanner() {
      const bar = new Date().getTime();
      sessionStorage.setItem('lineBnrClose', JSON.stringify(bar));
      this.isBannerClosed = true;
      /**
       * 부모컴포넌트로 데이터 전달 및 부모컴포넌트 함수 호출
       */
      this.$emit("bannerClose",this.isBannerClosed);
    },
  },
  created() {
    this.isBannerClosed = !!sessionStorage.getItem('lineBnrClose');
  },
};
</script>

<style lang="scss" scoped>
.promotion-banners {
  height: 90px;
  position: relative;
}

.close {
  position: absolute;
  right: 30px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 32px;
  height: 32px;
  cursor: pointer;
  z-index: 10;
  &::before {
    position: absolute;
    width: 24px;
    height: 1px;
    background: #fff;
    top: 15px;
    left: 4px;
    content: '';
    transform: rotate(45deg);
  }
  &::after {
    position: absolute;
    width: 24px;
    height: 1px;
    background: #fff;
    top: 15px;
    left: 4px;
    content: '';
    transform: rotate(135deg);
  }
}

.swiper {
  height: 100%;
}

.banner {
  display: block;
  height: 100%;
  img {
    display: block;
    margin: 0 auto;
    height: 100%;
    max-width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
}

@media (max-width: 767px) {
  .promotion-banners {
    height: 88px;
  }
}

@media (max-width: 1199px) {
  .close {
    width: 24px;
    height: 24px;
    right: 20px;
    &::after,
    &::before {
      width: 18px;
      top: 11px;
      left: 3px;
    }
  }
}
</style>
