import api from "@/common/api";
import env from "./env";
import storage from "./storage";
import user from "./user";

const SESS_KEY_GUEST_KEY = 'guestKey';

export default {
  recordUserAction(action: string) {
    const u = user.getLoggedInUser();

    let key = u && !u.isTestUser ? u.key : null;
    if (!key) key = storage.persistent.get(SESS_KEY_GUEST_KEY);
    if (key) {
      api.post(env.getApiUrl('/app/users/action'), { action, key });
    }
  },
};