/* eslint-disable */
import axios, { AxiosResponse } from 'axios';
import * as querystring from 'querystring';
import { env } from '@/tools';

const setCommonParams = (params) => {
  const newParams = params ? { ...params } : {};
  if (newParams.hasOwnProperty('key')) {
    return newParams;
  }
  newParams.key = localStorage.getItem('key') ? localStorage.getItem('key') : '';
  return newParams;
};

interface AxiosResponseCustom {
  result: number;
  message: string;
  [key: string]: any;
}

function getApiUrl(endpoint: string) {
  const sub = location.host.split('.')[0];
  const tmp = sub.split('-');
  let envId = tmp[0];
  if (envId === 'tutoring' || envId === 'jiayou' || envId === 'app') {
    return env.getApiUrl(endpoint);
  } else {
    if (envId === 'local') {
      envId = 'local';
    } else if (envId === 'dev') {
      envId = 'dev';
    } else if (envId === 'st') {
      envId = 'st';
    }
    return env.getApiUrl(endpoint, envId);
  }
}

const api = {
  get: async function (
    endpoint: string,
    params?: any,
    useCommonParams: boolean = true,
  ): Promise<AxiosResponse & AxiosResponseCustom> {
    const newParams = setCommonParams(params);
    return this._get(getApiUrl(endpoint), newParams, useCommonParams);
  },
  cmsGet: async function (endpoint: string, params?: any): Promise<AxiosResponse & AxiosResponseCustom> {
    const server = location.protocol + '//' + location.host;
    return this._get(`${server}${endpoint}`, params);
  },
  _get: async function (
    url: string,
    params?: any,
    useCommonParams: boolean = true,
  ): Promise<AxiosResponse & AxiosResponseCustom> {
    if (useCommonParams) {
      setCommonParams(params);
    }
    const response = await axios.get(url, { params });
    return response.data;
  },

  post: async function (endpoint: string, params?: any): Promise<AxiosResponse & AxiosResponseCustom> {
    const newParams = setCommonParams(params);
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
    };
    const response = await axios.post(getApiUrl(endpoint), querystring.stringify(newParams), {
      headers,
    });
    return response.data;
  },

  put: async function (endpoint: string, params?: any): Promise<void> {
    setCommonParams(params);
    const response = await axios.put(getApiUrl(endpoint), params);
    return response.data;
  },

  delete: async function (endpoint: string, params?: any): Promise<void> {
    setCommonParams(params);
    const response = await axios.delete(getApiUrl(endpoint), { params });
    return response.data;
  },
};

export default api;
