<template>
  <!-- 1200 이상 -->
  <div class="pc-header-wrapper" v-if="!isRecommendEventPage" :class="{ 'banner-close': isBannerClosed }">
    <Banner
      :banners="topPromotions.filter((item) => item.size === 768).map((item) => item.assets)[0] || []"
      @bannerClose="bannerClose"
    />
    <header class="header">
      <h1 class="logo">
        <a class="logo__image" @click="goTo('/v2/plan/all', 'mainlogo')">
          <img src="../assets/logo_lingora.png" alt="튜터링" />
        </a>
        <span class="logo__desc">국민가격 1:1 영어회화 앱</span>
      </h1>
      <nav class="service">
        <!-- <div class="event-banner">
          <div class="tooltip" @click="goToSaleFesta">튜터링 할인 페스타 진행 중!</div>
          <div class="triangle"></div>
          <div class="event" @click="goToSaleFesta">EVENT</div>
        </div> -->
        <ul class="service__list">
          <li class="service__item">
            <a @click="goTo('https://junior.tutoring.co.kr/', 'junior')">초등영어</a>
          </li>
          <li class="service__item">
            <a @click="goTo('/home/plan/chinese', 'jiayou')">중국어</a>
          </li>
          <li class="service__item">
            <a @click="goTo('/v2/b2bProgram', 'b2b')">B2B프로그램</a>
          </li>
          <!-- <li class="service__item service__coupon-download">
                        <a @click="goTo('/v2/pages/events/downloadCoupons', 'coupon')">쿠폰 다운로드</a>
                    </li> -->
          <li class="service__item">
            <a @click="goTo('/home/login', 'login')" v-if="!$tools.isLoggedIn()">회원가입/로그인</a>
            <a @click="logout" v-else>로그아웃</a>
          </li>
        </ul>
      </nav>
    </header>
    <nav class="menu">
      <ul class="menu__group">
        <li class="menu__item" v-for="m in menus.filter((m) => !m.code.includes('mobile'))" :key="m.code">
          <a
            :class="{ 'text--active': activeMenus.includes(m.code) }"
            @click="goTo(m.action.url, m.action.trackerName)"
          >
            {{ m.title }}
          </a>

          <div class="tag-badge-wrapper" v-if="m.badge">
            <span class="tag-badge" v-for="title in m.badge" :key="title">
              {{ title }}
            </span>
          </div>

          <div v-if="m.subMenu" class="submenu">
            <ul class="submenu__wrapper columns" v-if="m.code === 'plan__pc'">
              <li class="columns__col">
                <strong class="columns__title">베스트 수강권</strong>
                <ul class="columns__list">
                  <li class="columns__item" v-for="sub in m.subMenu.slice(0, 4)" :key="sub.code">
                    <a
                      :class="[
                        { 'badge badge--hit': sub.code === 'plan_all' },
                        {
                          'badge badge--new': sub.code === 'plan_PT_90',
                        },
                        {
                          'badge badge--new': sub.code === 'plan_PT_90_middle',
                        },
                        {
                          'badge badge--new': sub.code === 'chat',
                        },
                        { 'text--active text--bold': activeMenus.includes(sub.code) },
                      ]"
                      @click="goTo(sub.action.url, m.action.trackerName)"
                    >
                      {{ sub.title }}
                    </a>
                  </li>
                </ul>
              </li>
              <!-- <li class="columns__col">
                <strong class="columns__title">단과 수강권</strong>
                <ul class="columns__list">
                  <li class="columns__item" v-for="sub in m.subMenu.slice(3, 8)" :key="sub.code">
                    <a
                      :class="{ 'text--active text--bold': activeMenus.includes(sub.code) }"
                      @click="goTo(sub.action.url, m.action.trackerName)"
                    >
                      {{ sub.title }}
                    </a>
                  </li>
                </ul>
              </li> -->
              <!-- 프로모션 swiper-->
              <Swiper
                class="banners"
                :loop="true"
                :slidesPerView="1"
                :speed="500"
                :autoplay="{ dalay: 3000, disableOnInteraction: false }"
                :pagination="{ clickable: true }"
              >
                <SwiperSlide v-for="(item, index) in gnbPromotions" :key="index">
                  <a class="banners__item" @click="goTo(item.action.url, m.action.trackerName)">
                    <img :src="item.image" />
                  </a>
                </SwiperSlide>
              </Swiper>
            </ul>
            <ul class="submenu__wrapper" v-else>
              <li class="submenu__item" v-for="sub in m.subMenu" :key="sub.code">
                <a
                  :class="{ 'text--active text--bold': activeMenus.includes(sub.code) }"
                  @click="goTo(sub.action.url, m.action.trackerName)"
                >
                  {{ sub.title }}
                </a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </nav>
  </div>
  <!-- 1199 이하 -->
  <div class="mobile-header-wrapper" v-if="!isRecommendEventPage" :class="{ 'banner-close': isBannerClosed }">
    <Banner
      :banners="topPromotions.filter((item) => item.size === 360).map((item) => item.assets)[0] || []"
      @bannerClose="bannerClose"
    />
    <header class="header">
      <h1 class="logo">
        <a href="/v2/plan/all">
          <img src="../assets/logo_lingora.png" alt="튜터링" width="270px" />
        </a>
        <!-- <div class="event-banner">
          <div class="tooltip" @click="goToSaleFesta">튜터링 할인 페스타 진행 중!</div>
          <div class="triangle"></div>
          <div class="event" @click="goToSaleFesta">EVENT</div>
        </div> -->
      </h1>
      <button type="button" :class="['nav-btn', menuModal ? 'open' : 'close']" @click="toggleShowMenuModal">
        로그인 열기
      </button>
    </header>
    <nav class="menu" :style="{ paddingBottom: activeMenus.length > 1 ? '37px' : 0 }">
      <ul class="parent-menu">
        <li class="menu__item" v-for="m in menus.filter((m) => !m.code.includes('pc'))" :key="m.code">
          <a
            @click="goTo(m.action.url, m.action.trackerName)"
            :class="{ 'text--active text--bold': activeMenus.includes(m.code) }"
          >
            {{ m.title }}
          </a>

          <div class="tag-badge-wrapper" v-if="m.badge">
            <span class="tag-badge" v-for="badge in m.badge" :key="badge">
              {{ badge }}
            </span>
          </div>

          <ul class="child-menu" v-if="activeMenus.includes(m.code) && m.subMenu">
            <li class="menu__item" v-for="sub in m.subMenu" :key="sub.code">
              <a
                @click="goTo(sub.action.url, m.action.trackerName)"
                :class="{ 'text--active text--bold': activeMenus.includes(sub.code) }"
              >
                {{ sub.title }}
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
    <div class="modal-menu" :style="{ display: menuModal ? 'block' : 'none' }">
      <h2 class="title">
        24시간 언제나
        <br />
        당신만을 위한 1:1 영어 공부
      </h2>
      <a class="button" @click="goTo('/home/login', 'login')" v-if="!$tools.isLoggedIn()">회원가입/로그인하기</a>
      <a class="button" style="cursor: pointer" @click="logout" v-else>로그아웃하기</a>
      <div class="service">
        <ul class="service__list">
          <li class="service__item">
            <a @click="goTo('https://junior.tutoring.co.kr/', 'junior')">초등영어</a>
          </li>
          <li class="service__item">
            <a @click="goTo('/home/plan/chinese', 'jiayou')">중국어</a>
          </li>
          <li class="service__item">
            <a @click="goTo('/v2/b2bProgram', 'b2b')">B2B프로그램</a>
          </li>
          <!-- <li class="service__item service__coupon-download">
                        <a @click="goTo('/v2/pages/events/downloadCoupons', 'coupon')">쿠폰 다운로드</a>
                    </li> -->
        </ul>
      </div>
    </div>
  </div>
  <!-- logout form -->
  <form ref="logoutForm" style="display: none">
    <input type="hidden" name="_token" />
  </form>
</template>

<script lang="ts">
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { user, tracker } from '@/tools';
import api from '@/common/api';
import tools from '@/common/tools';

import menus from '../menus';
import Banner from './Banner.vue';

SwiperCore.use([Pagination, Autoplay]);

export default {
  name: 'Header',
  components: {
    Swiper,
    SwiperSlide,
    Banner,
  },
  data() {
    return {
      menus,
      activeMenus: [],
      topPromotions: [
        {
          size: 768,
          assets: [],
        },
        {
          size: 360,
          assets: [],
        },
      ],
      gnbPromotions: [],
      menuModal: false,
      pageName: null,
      isBannerClosed: false,
    };
  },
  computed: {
    isRecommendEventPage() {
      return this.$route.name?.indexOf('RecommendEvent') !== -1;
    },
  },
  watch: {
    $route() {
      const routeName = this.$route.name;
      const pageName = `${routeName[0].toLowerCase()}${routeName.slice(1)}`;
      this.pageName = pageName;
    },
  },
  methods: {
    /**
     * 상단 배너 닫기 이벤트 감지
     * @param newValue : isBannerClose valus
     */
    bannerClose(newValue) {
      this.isBannerClosed = newValue;
    },
    goTo(path, trackerName) {
      if ((trackerName && this.pageName === 'guideLesson') || this.pageName === 'downloadCoupons') {
        const trackerAction = `Web_${this.pageName}_${trackerName}`;
        tracker.recordUserAction(trackerAction);
      }
      if (path.startsWith('http')) {
        window.open(path);
      } else {
        window.location.href = `${window.location.protocol}//${window.location.host}${path}`;
      }
    },
    async setPromotions() {
      const loggedInUser = user.getLoggedInUser();
      //  mobileGNB: gnbPromotionsForMobile
      const {
        data: { promotions: topPromotions, webGNB: gnbPromotions },
      } = await api.get(
        '/app/etc/webPromotions',
        {
          mode: 'E',
          displayType: 15,
          channel: 1,
          isTestUser: loggedInUser?.isTestUser ? 1 : 0,
        },
        false,
      );
      this.topPromotions = topPromotions;
      this.gnbPromotions = gnbPromotions;
      // this.setMobilePromotionMenus(gnbPromotionsForMobile);
    },
    setMobilePromotionMenus(promotionPlans) {
      if (!promotionPlans || promotionPlans.length <= 0) return;
      const planMenu = this.menus.find((menu) => menu.code === 'plan__mobile');
      const planSubMenu = promotionPlans.map((plan) => {
        const {
          title,
          code,
          action: { url },
        } = plan;
        const newPlan = { title, code, action: { url } };
        return newPlan;
      });
      if (planMenu) {
        planMenu.subMenu = planSubMenu.concat(planMenu.subMenu);
      }
    },
    setActiveMenu() {
      let currentUrl;
      if (window.location.search) {
        currentUrl = window.location.pathname + window.location.search;
      } else {
        currentUrl = window.location.pathname;
      }

      console.log('currentUrl >>>', currentUrl);
      const viewType = window.innerWidth >= 1200 ? 'pc' : 'mobile';
      const filteredMenus = {
        pc: this.menus.filter((m) => !m.code.includes('mobile')),
        mobile: this.menus.filter((m) => !m.code.includes('pc')),
      }[viewType];
      this.activeMenus = filteredMenus.reduce((activeMenus, mainMenu) => {
        if (mainMenu.subMenu) {
          const subMenu = mainMenu.subMenu.find((sub) => currentUrl.includes(sub?.action.url));
          if (subMenu) {
            activeMenus.push(mainMenu.code);
            activeMenus.push(subMenu.code);
          }
        } else if (currentUrl === mainMenu.action.url) {
          activeMenus.push(mainMenu.code);
        }
        return activeMenus;
      }, []);
    },
    async checkAndRefreshLoggedInUser() {
      if (localStorage.getItem('key') && !localStorage.getItem('session.loggedInUser')) {
        await user.refreshLoggedInUser();
        window.location.reload();
      }
    },
    logout() {
      tracker.recordUserAction('Web_guideLesson_logout');
      tools.logout(this.$refs.logoutForm);
    },
    toggleShowMenuModal() {
      this.menuModal = !this.menuModal;
    },
    goToSaleFesta() {
      window.location.href = '/v2/promotions/salefesta';
    },
  },
  async mounted() {
    if (sessionStorage.getItem('lineBnrClose') != null) {
      this.isBannerClosed = true;
    }
    console.log('activeMenus >>>', this.activeMenus);

    // await this.checkAndRefreshLoggedInUser();
    // await this.setPromotions();
    this.setActiveMenu();
    window.addEventListener('resize', this.setActiveMenu);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.setActiveMenu);
  },
};
</script>

<style lang="scss" scoped>
.pc-header-wrapper {
  display: block;
  position: sticky;
  top: -90px;
  right: 0;
  left: 0;
  z-index: 100;
  border-bottom: 1px solid #d8d8d8;
  background-color: #fff;
  &.banner-close {
    top: 0px;
  }
  .header {
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
  .logo {
    display: flex;
    align-items: center;
    padding: 22px 0;
    &__image {
      width: 242px;
      height: 34px;
      margin-right: 20px;
      a,
      img {
        display: block;
        width: 100%;
        color: transparent;
        font-size: 10px;
        margin-top: 2px;
      }
    }
    &__desc {
      display: block;
      font-size: 15px;
      line-height: 1.2em;
      letter-spacing: -1px;
    }
  }
  .service {
    font-size: 0;
    margin-right: -10px;
    padding: 24px 0 20px;
    display: flex;
    align-items: center;
    &__item {
      display: inline-block;
      margin-left: 6px;
      a {
        display: block;
        padding: 0 10px;
        color: #777;
        font-size: 12px;
        line-height: 1.5em;
        letter-spacing: -0.8px;
      }
    }
    &__coupon-download {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        right: 0px;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: #ff1212;
      }
    }
    .event-banner {
      display: inline-block;
      .tooltip {
        display: inline-block;
        background-color: #ff00d6;
        color: #ffffff;
        border-radius: 8px;
        padding: 6px 12px;
        font-size: 14px;
        font-weight: 700;
        cursor: pointer;
      }
      .triangle {
        display: inline-block;
        border-style: solid;
        border-width: 3.75px 0 3.75px 7.5px;
        border-color: transparent transparent transparent #ff00d6;
      }
      .event {
        display: inline-block;
        background-color: rgba(255, 0, 214, 0.1);
        color: #ff00d6;
        border-radius: 8px;
        padding: 6px 12px;
        font-size: 14px;
        font-weight: 700;
        margin: 0 8px;
        cursor: pointer;
      }
    }
  }
  .menu {
    max-width: 1100px;
    margin: 0 auto;
    &__group {
      display: flex;
      gap: 50px;
    }
    &__item {
      /* padding-right: 50px; */
      & > a {
        display: block;
        padding: 14px 0 17px;
        font-size: 18px;
        line-height: 1.5em;
        letter-spacing: -1px;
      }
      &:hover {
        color: #662ddf;
        .submenu {
          visibility: visible;
        }
      }
    }
  }
  .submenu {
    position: relative;
    visibility: hidden;
    &__wrapper {
      position: absolute;
      top: -8px;
      min-width: 258px;
      border: 1px solid #6759ef;
      background: #fff;
      border-radius: 10px;
      backdrop-filter: blur(15px);
      overflow: hidden;
      display: flex;
      flex-direction: column;
    }
    &__item {
      padding: 10px 20px;
      border-top: 1px solid #f3f2f9;
      &:first-child {
        margin-top: -1px;
      }
      & > a {
        display: block;
        margin-top: 5px;
        margin-bottom: 4px;
        max-width: 100%;
        color: #666;
        font-size: 16px;
        font-weight: 300;
        line-height: 1.5em;
        letter-spacing: -1px;
        white-space: nowrap;
        text-overflow: ellipsis;
        &:hover {
          color: rgb(103, 89, 239);
          font-weight: bold;
        }
      }
    }
  }
  .columns {
    display: flex;
    padding: 0 24px;
    min-height: 380px;
    &__col {
      min-width: 130px;
      margin-right: 9px;
      margin-left: 8px;
      padding: 28px 0 0;
      vertical-align: top;
    }
    &__title {
      display: inline-block;
      margin-bottom: 10px;
      padding-bottom: 4px;
      border-bottom: 1px solid #c2c2c2;
      color: hsla(0, 0%, 60%, 0.6);
      font-size: 12px;
      font-weight: 600;
      line-height: 1.5em;
      letter-spacing: -0.8px;
      vertical-align: top;
    }
    &__item {
      overflow: hidden;
      margin-top: 4px;
      margin-bottom: 8px;
      max-width: 100%;
      color: #666;
      font-size: 16px;
      font-weight: 300;
      line-height: 1.5em;
      letter-spacing: -1px;
      white-space: nowrap;
      text-overflow: ellipsis;
      vertical-align: top;
      &:hover > a {
        color: rgb(103, 89, 239);
        font-weight: bold;
      }
    }
  }

  .badge {
    display: flex;
    align-items: center;
    font-weight: bold;
    &::after {
      display: flex;
      align-items: center;
      height: 16px;
      margin-left: 6px;
      padding: 0 4px;
      border: 1.5px solid #ff8fa2;
      color: #f93e5e;
      font-size: 9px;
      font-weight: 600;
      letter-spacing: -0.6px;
      line-height: 16px;
      background-color: #fff;
      box-sizing: border-box;
      border-radius: 16px;
    }
    &--hit::after {
      content: 'HIT';
    }
    &--new::after {
      content: 'NEW';
    }
  }
  .banners {
    width: 280px;
    height: 168px;
    align-self: flex-end;

    &__item {
      display: block;
      width: 100%;
    }
    img {
      width: 100%;
      vertical-align: top;
    }
    &::v-deep .swiper-pagination {
      position: relative;
      bottom: -145px;
    }
  }
  &::after {
    content: '';
    position: absolute;
    top: 78px;
    right: 0;
    left: 0;
    height: 1px;
    background: hsla(0, 0%, 84.7%, 0.3);
  }
}
.mobile-header-wrapper {
  position: sticky;
  top: -143px;
  z-index: 49;
  background-color: hsla(0, 0%, 100%, 0.95);
  &.banner-close {
    top: -54px;
  }
  .header {
    /* position: relative; */
    z-index: 50;
    padding: 13px 40px 13px 24px;
    border-bottom: 1px solid #dee1eb;
    box-sizing: border-box;
  }
  .logo {
    display: flex;
    justify-content: space-between;
    a {
      display: inline-block;
      width: 168px;
      height: 24px;
    }
    img {
      width: 100%;
      margin-top: 2px;
    }
    .event-banner {
      display: inline-block;
      vertical-align: super;
      @media (min-width: 768px) {
        margin-right: 40px;
        margin-top: 9px;
      }
      .tooltip {
        display: inline-block;
        background-color: #ff00d6;
        color: #ffffff;
        border-radius: 8px;
        padding: 6px 12px;
        font-size: 12px;
        font-weight: 700;
        cursor: pointer;
      }
      .triangle {
        display: inline-block;
        border-style: solid;
        border-width: 3.75px 0 3.75px 7.5px;
        border-color: transparent transparent transparent #ff00d6;
      }
      .event {
        display: inline-block;
        background-color: rgba(255, 0, 214, 0.1);
        color: #ff00d6;
        border-radius: 8px;
        padding: 6px 12px;
        font-size: 12px;
        font-weight: 700;
        margin: 0 8px 0 4px;
        cursor: pointer;
      }
    }
  }
  .nav-btn {
    position: absolute;
    right: 0;
    top: 0;
    padding: 17px;
    box-sizing: border-box;
    font-size: 0;
    &::before {
      content: '';
      display: block;
      height: 16px;
      background-image: url(//cdn.tutoring.co.kr/uploads/home/img/main/version3/ico_home_190402.png);
      background-size: 420px 400px;
    }
    &.close::before {
      background-position: -160px -110px;
      width: 22px;
    }
    &.open::before {
      background-position: -200px -110px;
      width: 16px;
    }
  }
  .menu {
    &__item > a {
      display: flex;
      justify-content: center;
      align-items: center;
      letter-spacing: -0.7px;
      position: relative;
    }
  }
  .parent-menu {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 12px;
    .menu__item > a {
      height: 40px;
      padding: 0 10px;
      font-size: 14px;
      position: relative;
    }
  }
  .child-menu {
    position: absolute;
    left: 0;
    top: 40px;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    // justify-content: space-between;
    padding: 0 12px;
    box-sizing: border-box;
    overflow-x: auto;
    border-top: 1px solid #dee1eb;
    .menu__item {
      width: 100%;
    }
    .menu__item > a {
      height: 36px;
      padding: 0 6px;
      font-size: 12px;
      white-space: nowrap;
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .modal-menu {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 40;
    padding-top: 200px;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    background-color: hsla(0, 0%, 100%, 0.9);
    text-align: center;
    .title {
      font-size: 20px;
      line-height: 34px;
      letter-spacing: -0.7px;
    }
    .button {
      display: inline-flex;
      min-width: 136px;
      height: 46px;
      margin-top: 36px;
      padding-right: 22px;
      padding-left: 22px;
      background-color: #6759ef;
      border-radius: 46px;
      color: #fff;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: -1px;
      align-items: center;
      justify-content: center;
    }
    .service {
      width: 100px;
      margin: 130px auto 0;
      padding-top: 26px;
      border-top: 1px solid #777;
      &__item {
        padding: 6px 0;
        a {
          display: block;
          padding: 0 10px;
          color: #777;
          font-size: 12px;
          line-height: 1.5em;
          letter-spacing: -0.8px;
        }
      }
      &__coupon-download {
        position: relative;
        &::before {
          content: '';
          position: absolute;
          right: 10px;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background: #ff1212;
        }
      }
    }
    &--active {
      display: flex;
    }
  }
  @media (min-width: 768px) {
    .modal-menu {
      padding-top: 200px;
    }
  }
}

.tag-badge-wrapper {
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: center;
}

.tag-badge {
  display: flex;
  height: 24px;
  padding: 0px var(--Spacing-xs, 8px);
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -12px;

  border-radius: var(--Radius-Round, 10000px);
  border: 1px solid var(--Primary-500, #662ddf);
  background: var(--Opacity-White, #fff);
  color: var(--Primary-500, #662ddf);
  font-size: 10px;
  font-weight: 700;
  line-height: 15px; /* 150% */

  @media (max-width: 1199px) {
    bottom: -13px;
    height: 20px;
  }
}

.text--active {
  color: #662ddf !important;
  font-weight: bold;
}

.text--bold {
  font-weight: bold;
}

a {
  cursor: pointer;
}

@media (max-width: 1199px) {
  .pc-header-wrapper {
    display: none;
  }
  .mobile-header-wrapper {
    display: block;
  }
}

@media (min-width: 1200px) {
  .pc-header-wrapper {
    display: block;
  }
  .mobile-header-wrapper {
    display: none;
  }
}

@media (min-width: 768px) {
  .mobile-header-wrapper {
    top: -179px;

    .header {
      padding: 24px 40px;
    }
    .logo {
      a {
        width: 270px;
        height: 40px;
        margin-top: 2px;
      }
    }
    .nav-btn {
      padding: 32px;
      &::before {
        height: 26px;
      }
      &.close::before {
        width: 36px;
        background-position: -160px -80px;
      }
      &.open::before {
        width: 26px;
        background-position: -198px -80px;
      }
    }
  }
}
</style>
