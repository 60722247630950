const device = {
  isMobile() {
    return (
      device.mobile.os.isAndroid() ||
      device.mobile.os.isIOS() ||
      device.mobile.os.isWindows() ||
      device.mobile.os.isBlackBerry()
    );
  },
  isGoogleBot() {
    return navigator.userAgent.match(/Googlebot/i);
  },
  getVerAppleWebKit() {
    const result = /AppleWebKit\/([\d.]+)/.exec(navigator.userAgent);
    return result ? Number(result[1]) : null;
  },

  mobile: {
    os: {
      isAndroid() {
        return navigator.userAgent.match(/Android/i);
      },
      isIOS() {
        return device.mobile.device.isIPhone() || device.mobile.device.isIPad();
      },
      isWindows() {
        return device.mobile.browser.isIEMobile();
      },
      isBlackBerry() {
        return navigator.userAgent.match(/BlackBerry/i);
      },
    },
    device: {
      isAndroidPhone() {
        return device.mobile.os.isAndroid();
      },
      isIPhone() {
        return navigator.userAgent.match(/iPhone|iPod/i);
      },
      isIPad() {
        return navigator.userAgent.match(/iPad/i);
      },
    },
    browser: {
      isChrome() {
        return navigator.userAgent.match(/Chrome/i);
      },
      isNaver() {
        return navigator.userAgent.match(/NAVER/i);
      },
      getVerNaver() {
        const result = /NAVER\(inapp; search; ([\d]+)/.exec(navigator.userAgent);
        return result ? Number(result[1]) : null;
      },

      isIEMobile() {
        return navigator.userAgent.match(/IEMobile/i);
      },

      isMbOperaMini() {
        return navigator.userAgent.match(/Opera Mini/i);
      },

      isSafari() {
        return navigator.userAgent.match(/Safari/i);
      },
    },
  },

  pc: {
    os: {
      isWindows() {
        return navigator.userAgent.match(/Windows/i);
      },
      isMac() {
        return navigator.userAgent.match(/Mackintosh/i);
      },
    },
    browser: {
      isChrome() {
        return navigator.userAgent.match(/Chrome/i);
      },
    },
  },
};

export default device;
