<template>
  <!-- footer -->
  <footer class="footer" v-if="!isRecommendEventPage">
    <div class="nav">
      <ul class="policy">
        <li><a href="/home/help">FAQ</a></li>
        <li><a href="/home/b2bProgram">B2B프로그램</a></li>
        <li><a href="/home/b2bProgram">단체수강 문의</a></li>
        <li><a href="/home/terms/policy">이용약관</a></li>
        <li>
          <a href="/home/terms/privacy"><b>개인정보처리방침</b></a>
        </li>
        <li>
          <a href="mailto:contact@tutoring.co.kr"><b>이메일 문의하기</b></a>
        </li>
      </ul>
    </div>
    <div class="info">
      <ul class="company">
        <li>
          <a href="https://www.notion.so/4921eefdfda44c0a997452c6ff769f43" target="blanck">회사소개</a>
        </li>
        <li><a href="/home/news">언론보도</a></li>
        <li>
          <a href="https://www.notion.so/53691a6d35a34711bb607e63c438eb32" target="blanck">입사지원</a>
        </li>
        <li><a href="/home/apply?mode=E&amp;lang=en">Apply to teach</a></li>

        <li>
          <a href="mailto:contact@tutoring.co.kr"><b>이메일 문의하기</b></a>
        </li>
      </ul>

      <details close>
        <summary>(주) 마켓디자이너스 사업자 정보</summary>
        <span>통합 법인 상호 (주) 마켓디자이너스</span>
        <span class="divider_bar">마켓디자이너스 대표 : 김현영</span>
        <br />
        <span>사업자 등록번호 840-87-00656</span>
        <br />
        <span>통신판매신고번호 2017-서울강남-01493</span>
        <br />
        <span>통신판매사업 대표번호 : 1522-7802</span>
        <br />
        <span>서울특별시 강남구 테헤란로7길 12, 3층</span>
      </details>

      <ul class="sns">
        <li>
          <a
            class="facebook"
            href="https://www.facebook.com/tutoring.co.kr/?timeline_context_item_type=intro_card_work&amp;timeline_context_item_source=100012123438239&amp;pnref=lhc"
            target="_blank"
          >
            <span class="ico ico--facebook">facebook</span>
          </a>
        </li>
        <li>
          <a class="naver" href="https://post.naver.com/tutoring360" target="_blank">
            <span class="ico ico--naver">naver</span>
          </a>
        </li>
        <li>
          <a class="brunch" href="https://brunch.co.kr/magazine/edtech" target="_blank">
            <span class="ico ico--brunch">brunch</span>
          </a>
        </li>
      </ul>
      <small class="copyright">Copyright © Tutoring.co.kr. All rights reserved</small>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  computed: {
    isRecommendEventPage() {
      return this.$route.name?.indexOf('RecommendEvent') !== -1;
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  position: relative;
  padding-bottom: 100px;
  color: #666;
  font-size: 14px;
  line-height: 1.4em;
  letter-spacing: -0.5px;
  text-align: center;
  background-color: #fff;
  border-top: 1px solid #dee1eb;
  overflow: hidden;
}

.copyright {
  font-size: inherit;
}

.nav {
  b {
    color: #333;
    font-weight: 600;
  }
  & > span {
    display: block;
    margin: 0 auto 10px;
  }
  &::after {
    content: '';
    display: block;
    width: 150vw;
    height: 1px;
    position: absolute;
    left: -50vw;
    bottom: 0;
    background-color: #f3f2f9;
  }
}

.nav,
.info {
  position: relative;
  z-index: 1;
  max-width: 1150px;
  margin: 0 auto;
  padding: 24px 0;
  box-sizing: border-box;
}

.policy {
  width: 250px;
  margin: 0 auto;
  li {
    display: inline-block;
    padding: 3px 4px;
    vertical-align: top;
    &::before {
      content: '';
      display: inline-block;
      width: 1px;
      height: 12px;
      margin: -1px 6px 0 -3px;
      background-color: #d8d8d8;
      vertical-align: middle;
    }
    &:first-child::before,
    &:nth-child(4)::before {
      display: none;
    }
  }
}

.company {
  li {
    display: inline-block;
    padding: 3px 4px;
    vertical-align: top;
    &.cell-offer {
      display: block;
    }
    &::before {
      content: '';
      display: inline-block;
      width: 1px;
      height: 12px;
      margin: -1px 6px 0 -3px;
      background-color: #d8d8d8;
      vertical-align: middle;
    }
    &.cell-offer::before,
    &:first-child::before {
      display: none;
    }
  }
}

button,
.button {
  display: inline-block;
  margin: -1px 0 1px;
  padding-right: 5px;
  padding-left: 7px;
  vertical-align: middle;
  .ico--arr {
    width: 14px;
    height: 9px;
    margin-top: 2px;
    background-position: -242px -110px;
  }
  &--active .ico--arr {
    transform: rotate(180deg);
  }
}

.sns {
  padding-top: 30px;
  padding-bottom: 10px;
  font-size: 0;
  li {
    display: inline-block;
    padding: 0 3px;
    vertical-align: top;
  }
  .ico {
    width: 42px;
    height: 42px;
    &--facebook {
      background-position: -270px 0;
    }
    &--naver {
      background-position: -316px 0;
    }
    &--brunch {
      background-position: -362px 0;
    }
  }
}

.ico {
  display: block;
  background-image: url(//cdn.tutoring.co.kr/uploads/home/img/main/version3/ico_home_3_190402.png);
  background-repeat: no-repeat;
  background-size: 420px 400px;
}

@media (min-width: 768px) and (max-width: 1199px) {
  .footer {
    font-size: 20px;
    line-height: 1.6em;
  }
  .nav > span {
    display: inline;
    padding-right: 10px;
  }
  .policy {
    width: 600px;
    li::before {
      height: 16px;
      margin: -2px 10px 0 0;
    }
  }
  .company li:before {
    height: 16px;
    margin: -2px 10px 0 0;
  }
}

@media (min-width: 1200px) {
  .footer {
    text-align: left;
  }

  .copyright {
    display: block;
    padding: 10px 4px;
  }

  .nav > span {
    display: inline-block;
    margin: 0;
    padding: 3px 4px;
  }

  .policy {
    width: auto;
  }

  .company li.cell-offer {
    display: inline-block;
    &::before {
      content: '';
      display: inline-block;
      width: 1px;
      height: 12px;
      margin: -1px 6px 0 -3px;
      background-color: #d8d8d8;
      vertical-align: middle;
    }
  }

  button,
  .button {
    display: none;
  }

  .sns {
    position: absolute;
    top: -87px;
    right: 0;
  }
}

details > summary {
  list-style: none;
  margin-bottom: 10px;
  margin-left: 5px;
}
span {
  margin-left: 5px;
}

summary::-webkit-details-marker {
  display: none;
}

summary::after {
  font-size: 10px;
  margin-left: 5px;
  content: '▼';
}
details[open] summary:after {
  font-size: 10px;
  margin-left: 5px;
  content: '▲';
}
.divider_bar {
  display: inline;
  &::before {
    content: '';
    display: inline-block;
    width: 1px;
    height: 12px;
    margin: -1px 10px 0;
    background-color: #d8d8d8;
    vertical-align: middle;
  }
  &:first-child::before {
    display: none;
  }
}
</style>
