import { render } from "./LandingSwiper.vue?vue&type=template&id=d8935422"
import script from "./LandingSwiper.vue?vue&type=script&lang=ts"
export * from "./LandingSwiper.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "d8935422"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('d8935422', script)) {
    api.reload('d8935422', script)
  }
  
  module.hot.accept("./LandingSwiper.vue?vue&type=template&id=d8935422", () => {
    api.rerender('d8935422', render)
  })

}

script.__file = "src/components/LandingSwiper.vue"

export default script