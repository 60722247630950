
export default {
  name: 'LandingCard',
  components: {},
  props: {},
  // data() {
  // 	return {
  // 		blocks: ["a", "b","c","d"],
  // 	};
  // },
  methods: {},
};
