import { createApp } from 'vue';
import mitt from 'mitt';
import { VueMasonryPlugin } from 'vue-masonry/src/masonry-vue3.plugin';
import VueScrollTo from 'vue-scrollto';
import vClickOutside from 'click-outside-vue3';
import * as braze from '@braze/web-sdk';
import dotenv from 'dotenv';
import { user } from '@/tools';
import tools from '@/common/tools';
import { router } from './routes';
// 라우터 추가하고

import App from './App.vue';

dotenv.config();

const brazeKey =
  process.env.NODE_ENV === 'development' ? process.env.VUE_APP_BRAZE_TEST_KEY : process.env.VUE_APP_BRAZE_REAL_KEY;

if (brazeKey) {
  braze.initialize(brazeKey, {
    baseUrl: 'sdk.iad-03.braze.com',
  });
  braze.automaticallyShowInAppMessages();

  const loggedInUser = user.getLoggedInUser();

  if (loggedInUser) {
    braze.changeUser(loggedInUser?.cusId);
    braze.logCustomEvent('login_web');
  }

  braze.openSession();
}

// Create Vue Instance

const emitter = mitt();

const app = createApp(App);
app.config.globalProperties.emitter = emitter;
app.config.globalProperties.$tools = tools;
app.use(VueMasonryPlugin);
app.use(vClickOutside);
app.directive('scroll-to', VueScrollTo);
app.use(router);
app.mount('#app');
