export default {
  isInApp() {
    return this.isAndroidInApp || this.isIOSInApp();
  },
  isAndroidInApp() {
    return !!window.AndroidNative;
  },
  isIOSInApp() {
    return (
      (navigator.userAgent.match(/iPhone|iPod/i) || navigator.userAgent.match(/iPad/i)) &&
      !navigator.userAgent.match(/Safari/i)
    );
  },

  getApiUrl(path, envId) {
    // eslint-disable-next-line no-param-reassign
    envId = envId || localStorage.getItem('apiEnvId');
    const prefix = envId ? `${envId}-` : '';
    return `https://${prefix}api.tutoring.co.kr${path}`;
  },
  getMvcUrl(path, envId) {
    // eslint-disable-next-line no-param-reassign
    envId = envId || localStorage.getItem('mvcEnvId');
    const prefix = envId ? `${envId}-` : '';
    return `https://${prefix}api.tutoring.co.kr${path}`;
  },
};