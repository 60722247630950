<template>
  <Header v-if="!appViewer" />
  <router-view />
  <ChannelIO v-show="!appViewer" />
  <Footer v-if="!appViewer" />
</template>

<script>
import { Header, Footer } from '@/components';
import ChannelIO from '@/common/Channel.vue';

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    ChannelIO,
  },
  computed: {
    appViewer() {
      // hostname에 app이 포함되어 있으면 header, footer , channelIO 제거 합니다
      if (document.location.hostname.includes('app')) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style>
/* @import "./assets/normalize.css"; */
@import './assets/tutoring_kr_real.css';

#app {
  min-width: 320px;
}
</style>
