<template>
  <div class="main_title">
    <h3 class="tit_main">
      {{ titleGroup[0] }}
      <b>{{ titleGroup[1] }}</b>
    </h3>
  </div>
  <div class="item_g item_merit">
    <div class="inner_g">
      <div class="slide_box">
        <swiper
          class="tutors-carousel"
          :navigation="true"
          :pagination="{ clickable: true }"
          :loop="true"
          :slidesPerView="3"
          :centeredSlides="true"
        >
          <swiper-slide v-for="(img, index) in swiperImg" :key="index">
            <strong class="tit_merit">
              <img :src="img" class="img_g" :alt="swiperCts[index]" />
            </strong>
            <p class="screen_out">{{ swiperCts[index] }}</p>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';

SwiperCore.use([Navigation, Pagination]);

export default {
  name: 'LandingSwiper',
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    titleGroup: {
      type: Array,
      required: true,
    },
    swiperImg: {
      type: Array,
      required: true,
    },
    swiperCts: {
      type: Array,
      required: true,
    },
  },
};
</script>
