// eslint-disable-next-line import/no-cycle
import native from "./native";

const EXPIRED_DATE_DELIMITER = '_|_';
const NONPERSISTENT_PREFIX = 'session.';

export default {
	persistent: {
		set(key:string, val:any, expiredDate?:Date) {
			// eslint-disable-next-line eqeqeq
			if (val == null || val == undefined) return;

			// eslint-disable-next-line no-use-before-define, no-param-reassign
			val = serialize(val);
			if (expiredDate) {
				// eslint-disable-next-line no-param-reassign
				val = expiredDate.getTime() + EXPIRED_DATE_DELIMITER + val;
			}

			localStorage.setItem(key, val);
			native.setPersistent(key, val);
		},
		get(key:string) {
			let val = localStorage.getItem(key);
			if (val && val.indexOf(EXPIRED_DATE_DELIMITER) > 0) {
				const tmp = val.split(EXPIRED_DATE_DELIMITER);
				const expiredTs = tmp[0];
				if (new Date().getTime() > Number(expiredTs)) {
					this.remove(key);
					return null;
				// eslint-disable-next-line no-else-return
				} else {
					// eslint-disable-next-line prefer-destructuring
					val = tmp[1];
				}
			}

			// eslint-disable-next-line no-use-before-define
			val = unserialize(val);
			return val;
		},
		remove: (key:string) => {
			localStorage.removeItem(key);
			native.setPersistent(key, null);
		}
	},
	session: {
		set: (key:string, val:any) => {
			// eslint-disable-next-line eqeqeq
			if (val == null || val == undefined) return;

			// eslint-disable-next-line no-use-before-define, no-param-reassign
			val = serialize(val);
			localStorage.setItem(NONPERSISTENT_PREFIX + key, val);
			native.setNonPersistent(key, val);
		},
		get: (key:string) => {
			const val = localStorage.getItem(NONPERSISTENT_PREFIX + key);
			// eslint-disable-next-line no-use-before-define
			const obj = unserialize(val);
			return obj;
		},
		remove: (key:string) => {
			localStorage.removeItem(NONPERSISTENT_PREFIX + key);
			native.setNonPersistent(key, null);
		}
	},
}


function serialize(value:any) {
	if (typeof value === 'object') {
		try {
      // eslint-disable-next-line no-param-reassign
      value = JSON.stringify(value);
      // eslint-disable-next-line no-empty
    } catch (ex) {
		}
	} else if (typeof value === 'number') {
		// eslint-disable-next-line no-param-reassign
		value = value.toString();
	}

	return value;
}

function unserialize(value:string|null) : any {
	if (value) {
		try {
			// eslint-disable-next-line no-param-reassign
			value = JSON.parse(value);
		// eslint-disable-next-line no-empty
		} catch (ex) {
		}
	}

	return value;
}