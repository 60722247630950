import qs from 'qs';
import env from './env';
import device from './device';
// eslint-disable-next-line import/no-cycle
import storage from './storage';

const appSchemeUrl = 'tutoring://app/';
// eslint-disable-next-line no-restricted-globals
const appBaseUrl = `${location.protocol}//${location.host}`;

if (!window.C) {
	window.C = {
		NativeTool: {
			onPause: () => {},
			onResume: () => {},
			onBackPressed: () => {
				// eslint-disable-next-line no-use-before-define
				native.close();
			}
		},
	};
}
if (!window.C.NativeTool.callbackFromNative) {
	window.C.NativeTool.callbackFromNative = {};
}


const native = {
	_onReumseTimer: null,
	isAndroid() {
    return !!device.mobile.os.isAndroid();
  },
  isIOS(){
    return !!device.mobile.os.isIOS();
  },
	iosAsyncSchemeCall(action, params, timeOut) {
		// 이함수 안에서는 console.log만 사용해야 함 (debug.log 사용시 무한 재귀 루프에 빠짐)
		return new Promise((resolve, reject) => {
			let timer;
			if (env.isIOSInApp()) {
				if(window.C && window.C.NativeTool && window.C.NativeTool.callbackFromNative) {
					// eslint-disable-next-line func-names
					window.C.NativeTool.callbackFromNative[action] = function (obj) {
						// eslint-disable-next-line no-unused-expressions
						timer && clearInterval(timer);
						resolve(obj);
					};
				}

				let queryString =
					typeof params === 'string' ? params : qs.stringify(params);
				// eslint-disable-next-line eqeqeq
				queryString = queryString != undefined ? queryString : '';

				window.webkit.messageHandlers.observe.postMessage(
					`${appSchemeUrl}[[X]]${action}[[X]]${queryString}`,
				);

				if (timeOut) {
					timer = setInterval(() => {
						// eslint-disable-next-line no-empty
						if(!timeOut) {
						} else if (timeOut > 0) {
							// eslint-disable-next-line no-param-reassign
							timeOut -= 1;
							window.webkit.messageHandlers.observe.postMessage(
								`${appSchemeUrl}[[X]]${action}[[X]]${queryString}`,
							);
						} else if (timeOut === 0) {
							clearInterval(timer);
							// eslint-disable-next-line prefer-promise-reject-errors
							reject('no response');
						}
					}, 2000);
				}
			} else {
				// 디버깅용으로 사용
				// if(action == 'get_native_info') {
				//   return {os:1}
				// }
			}
		});
	},

	setPersistent(key, val) {
		if (env.isAndroidInApp()) {
			window.AndroidNative.setPersistent(key, val);
		}
		else if (env.isIOSInApp()) {
			this.iosAsyncSchemeCall('set_persistent', {key, value: (val===null?'':val)});
		}
	},
	
	setNonPersistent(key, val) {
		if (env.isAndroidInApp()) {
			window.AndroidNative.setNonPersistent(key, val);
		}
		else if (env.isIOSInApp()) {
			this.iosAsyncSchemeCall('set_non_persistent', {key, value: (val===null?'':val)});
		}
	},

	openGoodsOrder() {
		if (env.isAndroidInApp()) {
			window.AndroidNative.openGoodsOrder('');
		} else if (env.isIOSInApp()) {
			this.iosAsyncSchemeCall('open_goods_order', '');
		}
	},

  openWebView(url, title) {
		const navTitle = title || '';
		const fullUrl = url.startsWith('http') ? url : `${appBaseUrl}${url}`;

    if (env.isAndroidInApp()) {
      window.AndroidNative.openWebView(fullUrl, navTitle);
    } else if (env.isIOSInApp()) {
      this.iosAsyncSchemeCall('open_web_view', {url: fullUrl, title: navTitle});
    } else {
      const winref = window.open(
        fullUrl,
        'pc_popup',
        'top=100, left=500, width=400, height=700,location=no',
      );
      window.C?.NativeTool.onPause();
      if (this._onReumseTimer) {
        clearInterval(this._onReumseTimer);
      }
      this._onReumseTimer = setInterval(() => {
        if (winref?.closed) {
          clearInterval(this._onReumseTimer);
          window.C?.NativeTool.onResume();
        }
      }, 1000);
    }
  },

	close(msg) {
		storage.session.remove('needToExitApp');

		if (typeof msg === 'undefined' || msg === null) {
			if (env.isAndroidInApp()) {
				window.AndroidNative.closeFromWebView();
			} else if (env.isIOSInApp()) {
				this.iosAsyncSchemeCall('close');
			}
		} else if (env.isAndroidInApp()) {
				window.AndroidNative.closeFromWebView(msg);
			} else if (env.isIOSInApp()) {
				this.iosAsyncSchemeCall('close', {msg});
			}
	},
}



export default native;