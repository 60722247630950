<template>


  <Header v-if="!appViewer" />


  <router-view />


  <ChannelIO v-show="!appViewer" />


  <Footer v-if="!appViewer" />


</template>


<script>
import { Header, Footer } from '@/components';
import ChannelIO from '@/common/Channel.vue';

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    ChannelIO,
  },
  computed: {
    appViewer() {
      const { hostname } = document.location;

      // 'lingora.app'는 제외하고, 'app'이 포함된 경우만 true
      // 앱 화면에서 프로모션을 노출하는 경우는 헤더, 푸터, 채널IO를 숨겨야함
      return hostname.includes('app') && !hostname.includes('lingora.app');
    },
  },
};
</script>


<style>
/* @import "./assets/normalize.css"; */
@import './assets/tutoring_kr_real.css';

#app {
  min-width: 320px;
}
</style>


