/* eslint-disable */
import axios, { AxiosResponse } from 'axios';
import * as querystring from 'querystring';

const setCommonParams = (params) => {
  const newParams = params ? { ...params } : {};
  if (newParams.hasOwnProperty('key')) {
    return newParams;
  }
  newParams.key = localStorage.getItem('key') ? localStorage.getItem('key') : '';
  return newParams;
};

interface AxiosResponseCustom {
  result: number;
  message: string;
  [key: string]: any;
}

function getApiUrl(endpoint: string) {
  const host = location.host; // 예: dev.lingora.app, st.tutoring.co.kr 등
  const isProdDomain = host === 'lingora.app' || host === 'tutoring.co.kr';

  if (isProdDomain) {
    // 운영 환경은 무조건 api.tutoring.co.kr
    return `https://api.tutoring.co.kr${endpoint}`;
  }

  // dev.lingora.app, st.tutoring.co.kr 등의 서브도메인 추출
  const prefix = host.split('.')[0]; // dev, st 등

  // 로컬환경은 st로 연결
  const envId = prefix === 'local' ? 'st' : prefix;

  return `https://${envId}-api.tutoring.co.kr${endpoint}`;
}

const api = {
  get: async function (
    endpoint: string,
    params?: any,
    useCommonParams: boolean = true,
  ): Promise<AxiosResponse & AxiosResponseCustom> {
    const newParams = setCommonParams(params);
    return this._get(getApiUrl(endpoint), newParams, useCommonParams);
  },
  cmsGet: async function (endpoint: string, params?: any): Promise<AxiosResponse & AxiosResponseCustom> {
    const server = location.protocol + '//' + location.host;
    return this._get(`${server}${endpoint}`, params);
  },
  _get: async function (
    url: string,
    params?: any,
    useCommonParams: boolean = true,
  ): Promise<AxiosResponse & AxiosResponseCustom> {
    if (useCommonParams) {
      setCommonParams(params);
    }
    const response = await axios.get(url, { params });
    return response.data;
  },

  post: async function (endpoint: string, params?: any): Promise<AxiosResponse & AxiosResponseCustom> {
    const newParams = setCommonParams(params);
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
    };
    const response = await axios.post(getApiUrl(endpoint), querystring.stringify(newParams), {
      headers,
    });
    return response.data;
  },

  put: async function (endpoint: string, params?: any): Promise<void> {
    setCommonParams(params);
    const response = await axios.put(getApiUrl(endpoint), params);
    return response.data;
  },

  delete: async function (endpoint: string, params?: any): Promise<void> {
    setCommonParams(params);
    const response = await axios.delete(getApiUrl(endpoint), { params });
    return response.data;
  },
};

export default api;
