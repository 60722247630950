
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';

SwiperCore.use([Navigation, Pagination]);

export default {
  name: 'LandingSwiper',
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    titleGroup: {
      type: Array,
      required: true,
    },
    swiperImg: {
      type: Array,
      required: true,
    },
    swiperCts: {
      type: Array,
      required: true,
    },
  },
};
