<template>
  <div v-if="appView">
    <span class="app-navbar">
      <span class="app-navbar__back" @click="backToPage" />
      <slot />
    </span>
  </div>
</template>

<script lang="ts">
import { native, tracker } from '@/tools';
import inappTools from '@/common/inappTools';

export default {
  name: 'AppNav',
  computed: {
    appView() {
      return inappTools.isInApp();
    },
  },
  methods: {
    backToPage() {
      const routeName = this.$route.name;
      if (routeName.toLowerCase() === 'guidelesson') {
        const pageName = `${routeName[0].toLowerCase()}${routeName.slice(1)}`;
        tracker.recordUserAction(`Lesson_${pageName}_back`);
      }
      if (inappTools.isInApp()) {
        native.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.app-navbar {
  position: fixed;
  top: 0;
  z-index: 49;
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: white;
  color: #121214;
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.5px;
  &__back {
    z-index: 49;
    width: 50px;
    height: 50px;
    position: fixed;
    &::before {
      position: absolute;
      left: 12px;
      bottom: 17px;
      width: 10px;
      height: 10px;
      margin-left: 8px;
      border-top: 2px solid #121214;
      color: #121214;
      border-left: 2px solid black;
      transform: rotate(-45deg);
      content: '';
    }
  }
}
</style>
