
import { native, tracker } from '@/tools';
import inappTools from '@/common/inappTools';

export default {
  name: 'AppNav',
  computed: {
    appView() {
      return inappTools.isInApp();
    },
  },
  methods: {
    backToPage() {
      const routeName = this.$route.name;
      if (routeName.toLowerCase() === 'guidelesson') {
        const pageName = `${routeName[0].toLowerCase()}${routeName.slice(1)}`;
        tracker.recordUserAction(`Lesson_${pageName}_back`);
      }
      if (inappTools.isInApp()) {
        native.close();
      }
    },
  },
};
