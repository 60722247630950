import api from "@/common/api";
import storage from "./storage";

interface ISessionLoggedInUser {
	basic: {
		is_test_user: '0'|'1',
		cus_id: string,
		is_b2b: '0'|'1'
	},
	goods: {},
	lms: {},
	segInfo: {}
}


export interface ILoggedInUser {
  key: string;
  cusId: string;
  isTestUser: boolean;
  isB2BUser: boolean;
}

export default {
	async refreshLoggedInUser() {
		const key = storage.persistent.get('key');
		if(!key) return null;
		const data = await api.post('/mvc/login_by_key',{key});
		const user:ISessionLoggedInUser = {
			basic: data.basic_info,
			goods: data.goods_info,
			lms: data.lms_info,
			segInfo: data.seg_info,
		}
		storage.session.set('loggedInUser', user);
	},

	getLoggedInUser() : ILoggedInUser | null {
		const key = storage.persistent.get('key');
		const sUser:ISessionLoggedInUser = storage.session.get('loggedInUser');
		if(!key || !sUser) return null;

		const user:ILoggedInUser = {
			key,
			cusId: sUser.basic.cus_id,
			isTestUser: (sUser.basic.is_test_user==='1'),
			isB2BUser: (sUser.basic.is_b2b==='1')
		}
		return user;
	}
} 