import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a2fea148"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "promotion-banners"
}
const _hoisted_2 = ["href"]
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SwiperSlide = _resolveComponent("SwiperSlide")!
  const _component_Swiper = _resolveComponent("Swiper")!

  return (!$data.isBannerClosed && $props.banners?.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "close",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.closeBanner && $options.closeBanner(...args)))
        }),
        _createVNode(_component_Swiper, {
          class: "swiper",
          direction: "vertical",
          loop: true,
          slidesPerView: 1,
          speed: 500,
          autoplay: { dalay: 3000, disableOnInteraction: false }
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.banners, (banner, index) => {
              return (_openBlock(), _createBlock(_component_SwiperSlide, {
                key: index,
                class: "slide"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("a", {
                    class: "banner",
                    href: banner.action.url,
                    style: _normalizeStyle({
            background: `url(${banner.backgroundImage})`,
          })
                  }, [
                    _createElementVNode("img", {
                      src: banner.image
                    }, null, 8 /* PROPS */, _hoisted_3)
                  ], 12 /* STYLE, PROPS */, _hoisted_2)
                ]),
                _: 2 /* DYNAMIC */
              }, 1024 /* DYNAMIC_SLOTS */))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        })
      ]))
    : _createCommentVNode("v-if", true)
}