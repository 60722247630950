export type Menu = {
  title: string;
  code: string;
  action: {
    url: string;
    trackerName?: string;
  };
  subMenu?: Array<Menu>;
  badge?: {
    title: string;
  };
};

const menus: Array<Menu> = [
  {
    title: '소개',
    code: 'home',
    action: {
      url: '/v2/',
      trackerName: 'introduce',
    },
  },
  // PC 수강신청 탭
  // {
  //   title: '수강 신청',
  //   code: 'plan__pc',
  //   action: {
  //     url: '/v2/plan/all',
  //     trackerName: 'gnb_plan',
  //   },
  //   subMenu: [
  //     {
  //       title: '365 올패스',
  //       code: 'plan_all',
  //       action: {
  //         url: '/v2/plan/all',
  //       },
  //     },
  // {
  //   title: '작심 패키지',
  //   code: 'plan_PT_90',
  //   action: {
  //     url: '/v2/promotions/pr90days',
  //   },
  // },

  //     {
  //       title: '채팅 패스',
  //       code: 'chat',
  //       action: {
  //         url: '/v2/promotions/chat',
  //       },
  //     },
  //     // {
  //     //   title: '튜터링 밋업',
  //     //   code: 'meetup',
  //     //   action: {
  //     //     url: '/v2/promotions/meetup',
  //     //   },
  //     // },
  //     // {
  //     //   title: '단과 종합',
  //     //   code: 'plan_single',
  //     //   action: {
  //     //     url: '/v2/plan/single',
  //     //   },
  //     // },
  //     // {
  //     //   title: '기초 회화',
  //     //   code: 'plan_single_740',
  //     //   action: {
  //     //     url: '/v2/plan/single/740',
  //     //   },
  //     // },
  //     // {
  //     //   title: '일상 영어',
  //     //   code: 'plan_single_618',
  //     //   action: {
  //     //     url: '/v2/plan/single/618',
  //     //   },
  //     // },
  //     // {
  //     //   title: '원어민 표현',
  //     //   code: 'plan_single_742',
  //     //   action: {
  //     //     url: '/v2/plan/single/742',
  //     //   },
  //     // },
  //     // {
  //     //   title: '비즈니스 영어',
  //     //   code: 'plan_single_632',
  //     //   action: {
  //     //     url: '/v2/plan/single/632',
  //     //   },
  //     // },
  //   ],
  // },
  // 모바일 수강신청 탭
  // {
  //   title: '수강신청',
  //   code: 'plan__mobile',
  //   action: {
  //     url: '/v2/plan/all',
  //     trackerName: 'gnb_plan',
  //   },
  //   subMenu: [
  //     {
  //       title: '365올패스',
  //       code: 'plan_all',
  //       action: {
  //         url: '/v2/plan/all',
  //       },
  //     },
  // {
  //   title: '작심 패키지',
  //   code: 'plan_PT_90',
  //   action: {
  //     url: '/v2/promotions/pr90days',
  //   },
  // },

  //     {
  //       title: '채팅 패스',
  //       code: 'chat',
  //       action: {
  //         url: '/v2/promotions/chat',
  //       },
  //     },
  //   ],
  //   // 	{
  //   // 		title: "단과종합",
  //   // 		code: "plan_single",
  //   // 		action: {
  //   // 			url: "/home/plan/single",
  //   // 		},
  //   // 	},
  //   // 	{
  //   // 		title: "기초 회화",
  //   // 		code: "plan_single_740",
  //   // 		action: {
  //   // 			url: "/home/plan/single/740",
  //   // 		},
  //   // 	},
  //   // 	{
  //   // 		title: "일상 영어",
  //   // 		code: "plan_single_618",
  //   // 		action: {
  //   // 			url: "/home/plan/single/618",
  //   // 		},
  //   // 	},
  //   // 	{
  //   // 		title: "원어민 표현",
  //   // 		code: "plan_single_742",
  //   // 		action: {
  //   // 			url: "/home/plan/single/742",
  //   // 		},
  //   // 	},
  //   // 	{
  //   // 		title: "비즈니스 영어",
  //   // 		code: "plan_single_632",
  //   // 		action: {
  //   // 			url: "/home/plan/single/632",
  //   // 		},
  //   // 	},
  //   // 	{
  //   // 		title: "직딩의 영테크",
  //   // 		code: "plan_worker",
  //   // 		action: {
  //   // 			url: "/home/plan/worker",
  //   // 		},
  //   // 	},
  //   // ],
  // },
  {
    title: '무료수업 신청',
    code: 'ClassApply',
    action: {
      url: '/v2/classApply',
      trackerName: 'gnb_leveltest',
    },
    badge: {
      title: '1:1 원어민 회화',
    },
    // subMenu: [
    // 	{
    // 		title: "원어민 1:1 무료 체험",
    // 		code: "appInfo_trial",
    // 		action: {
    // 			url: "/v2/appInfo",
    // 		},
    // 	},
    // 	{
    // 		title: "AI 레벨 테스트",
    // 		code: "appInfo_aiLevelTest",
    // 		action: {
    // 			url: "/home/aiLevelTest",
    // 		},
    // 	},
    // ],
  },
  {
    title: '수강 후기',
    code: 'review__pc',
    action: {
      url: '/home/review',
      trackerName: 'gnb_review',
    },
  },
  // mobile
  {
    title: '수강후기',
    code: 'review__mobile',
    action: {
      url: '/home/review',
      trackerName: 'gnb_review',
    },
  },
  {
    title: '튜터',
    code: 'tutors__pc',
    action: {
      url: '/home/tutors',
      trackerName: 'gnb_tutor',
    },
    subMenu: [
      {
        title: '영어 튜터',
        code: 'tutors_en',
        action: {
          url: '/home/tutors',
        },
      },
      {
        title: '중국어 튜터',
        code: 'tutors_ch',
        action: {
          url: '/home/tutors/L',
        },
      },
    ],
  },
  {
    title: '토픽',
    code: 'topics__pc',
    action: {
      url: '/home/topics',
      trackerName: 'gnb_topic',
    },
    subMenu: [
      {
        title: '영어 토픽',
        code: 'topics_en',
        action: {
          url: '/home/topics/english',
        },
      },
      {
        title: '중국어 토픽',
        code: 'topics_cn',
        action: {
          url: '/home/topics/chinese',
        },
      },
    ],
  },
  {
    title: '첨삭',
    code: 'correction',
    action: {
      url: '/home/correction',
      trackerName: 'gnb_proofreading',
    },
  },
];

export default menus;
