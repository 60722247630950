
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';

SwiperCore.use([Autoplay]);

export default {
  name: 'Banner',
  props: {
    banners: {
      type: Array,
      requierd: true,
    },
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      isBannerClosed: false,
    };
  },
  methods: {
    closeBanner() {
      const bar = new Date().getTime();
      sessionStorage.setItem('lineBnrClose', JSON.stringify(bar));
      this.isBannerClosed = true;
      /**
       * 부모컴포넌트로 데이터 전달 및 부모컴포넌트 함수 호출
       */
      this.$emit("bannerClose",this.isBannerClosed);
    },
  },
  created() {
    this.isBannerClosed = !!sessionStorage.getItem('lineBnrClose');
  },
};
