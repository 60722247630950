
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { user, tracker } from '@/tools';
import api from '@/common/api';
import tools from '@/common/tools';

import menus from '../menus';
import Banner from './Banner.vue';

SwiperCore.use([Pagination, Autoplay]);

export default {
  name: 'Header',
  components: {
    Swiper,
    SwiperSlide,
    Banner,
  },
  data() {
    return {
      menus,
      activeMenus: [],
      topPromotions: [
        {
          size: 768,
          assets: [],
        },
        {
          size: 360,
          assets: [],
        },
      ],
      gnbPromotions: [],
      menuModal: false,
      pageName: null,
      isBannerClosed: false,
    };
  },
  computed: {
    isRecommendEventPage() {
      return this.$route.name?.indexOf('RecommendEvent') !== -1;
    },
  },
  watch: {
    $route() {
      const routeName = this.$route.name;
      const pageName = `${routeName[0].toLowerCase()}${routeName.slice(1)}`;
      this.pageName = pageName;
    },
  },
  methods: {
    /**
     * 상단 배너 닫기 이벤트 감지
     * @param newValue : isBannerClose valus
     */
    bannerClose(newValue) {
      this.isBannerClosed = newValue;
    },
    goTo(path, trackerName) {
      if ((trackerName && this.pageName === 'guideLesson') || this.pageName === 'downloadCoupons') {
        const trackerAction = `Web_${this.pageName}_${trackerName}`;
        tracker.recordUserAction(trackerAction);
      }
      if (path.startsWith('http')) {
        window.open(path);
      } else {
        window.location.href = `${window.location.protocol}//${window.location.host}${path}`;
      }
    },
    async setPromotions() {
      const loggedInUser = user.getLoggedInUser();
      //  mobileGNB: gnbPromotionsForMobile
      const {
        data: { promotions: topPromotions, webGNB: gnbPromotions },
      } = await api.get(
        '/app/etc/webPromotions',
        {
          mode: 'E',
          displayType: 15,
          channel: 1,
          isTestUser: loggedInUser?.isTestUser ? 1 : 0,
        },
        false,
      );
      this.topPromotions = topPromotions;
      this.gnbPromotions = gnbPromotions;
      // this.setMobilePromotionMenus(gnbPromotionsForMobile);
    },
    setMobilePromotionMenus(promotionPlans) {
      if (!promotionPlans || promotionPlans.length <= 0) return;
      const planMenu = this.menus.find((menu) => menu.code === 'plan__mobile');
      const planSubMenu = promotionPlans.map((plan) => {
        const {
          title,
          code,
          action: { url },
        } = plan;
        const newPlan = { title, code, action: { url } };
        return newPlan;
      });
      if (planMenu) {
        planMenu.subMenu = planSubMenu.concat(planMenu.subMenu);
      }
    },
    setActiveMenu() {
      let currentUrl;
      if (window.location.search) {
        currentUrl = window.location.pathname + window.location.search;
      } else {
        currentUrl = window.location.pathname;
      }

      console.log('currentUrl >>>', currentUrl);
      const viewType = window.innerWidth >= 1200 ? 'pc' : 'mobile';
      const filteredMenus = {
        pc: this.menus.filter((m) => !m.code.includes('mobile')),
        mobile: this.menus.filter((m) => !m.code.includes('pc')),
      }[viewType];
      this.activeMenus = filteredMenus.reduce((activeMenus, mainMenu) => {
        if (mainMenu.subMenu) {
          const subMenu = mainMenu.subMenu.find((sub) => currentUrl.includes(sub?.action.url));
          if (subMenu) {
            activeMenus.push(mainMenu.code);
            activeMenus.push(subMenu.code);
          }
        } else if (currentUrl === mainMenu.action.url) {
          activeMenus.push(mainMenu.code);
        }
        return activeMenus;
      }, []);
    },
    async checkAndRefreshLoggedInUser() {
      if (localStorage.getItem('key') && !localStorage.getItem('session.loggedInUser')) {
        await user.refreshLoggedInUser();
        window.location.reload();
      }
    },
    logout() {
      tracker.recordUserAction('Web_guideLesson_logout');
      tools.logout(this.$refs.logoutForm);
    },
    toggleShowMenuModal() {
      this.menuModal = !this.menuModal;
    },
    goToSaleFesta() {
      window.location.href = '/v2/promotions/salefesta';
    },
  },
  async mounted() {
    if (sessionStorage.getItem('lineBnrClose') != null) {
      this.isBannerClosed = true;
    }
    console.log('activeMenus >>>', this.activeMenus);

    // await this.checkAndRefreshLoggedInUser();
    // await this.setPromotions();
    this.setActiveMenu();
    window.addEventListener('resize', this.setActiveMenu);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.setActiveMenu);
  },
};
