import { render } from "./Banner.vue?vue&type=template&id=a2fea148&scoped=true"
import script from "./Banner.vue?vue&type=script&lang=ts"
export * from "./Banner.vue?vue&type=script&lang=ts"

import "./Banner.vue?vue&type=style&index=0&id=a2fea148&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-a2fea148"
/* hot reload */
if (module.hot) {
  script.__hmrId = "a2fea148"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('a2fea148', script)) {
    api.reload('a2fea148', script)
  }
  
  module.hot.accept("./Banner.vue?vue&type=template&id=a2fea148&scoped=true", () => {
    api.rerender('a2fea148', render)
  })

}

script.__file = "src/components/Banner.vue"

export default script