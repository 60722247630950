import { storage, native } from '@/tools';

const inappTools = {
  isInApp(): boolean {
    return document.location.hostname.includes('app');
  },

  openGoodsOrder() {
    const basic = storage.session.get('myBasicInfo');

    if (basic && typeof basic === 'object' && Object.entries(basic).length > 0) {
      // eslint-disable-next-line camelcase
      const { is_observer = 1 } = basic;

      if (Number(is_observer)) {
        // eslint-disable-next-line no-restricted-globals
        location.href = `https://${location.host}/web/subscription.html`;
      } else {
        native.openGoodsOrder();
      }
    } else {
      // TODO leo: (eddy) 임시 조치 추후 수정 바람
      alert('인증 문제가 발생하였습니다. 앱을 종료후 다시 시도 해주세요.');
    }
  },
  goToGoodsPage(params) {
    const basic = storage.session.get('myBasicInfo');

    if (basic && typeof basic === 'object' && Object.entries(basic).length > 0) {
      // eslint-disable-next-line camelcase
      const { is_observer = 1 } = basic;

      if (Number(is_observer)) {
        window.location.href = `https://${window.location.host}/web/subscription.html`;
      } else if (native.isAndroid()) {
        // @ts-ignore
        window.AndroidNative.openGoodsOrder(params);
      } else if (native.isIOS()) {
        native.iosAsyncSchemeCall('open_goods_order', params);
      }
    } else {
      alert('인증 문제가 발생하였습니다. 앱을 종료후 다시 시도 해주세요.');
    }
  },
  openWebBroswerNoPay(url) {
    if (native.isAndroid()) {
      // @ts-ignore
      window.AndroidNative.openWebBrowser(url);
    } else if (native.isIOS()) {
      const encodedUrl = encodeURIComponent(url);
      native.iosAsyncSchemeCall('openWebBrowser', `url=${encodedUrl}`);
    }
  },
};

export default inappTools;
